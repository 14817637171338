.title2 {
  font-size: 26px;
  font-weight: normal;
}

.title3 {
  font-size: 20px;
  font-weight: normal;
}

.bold {
  font-family: "MontserratBold", sans-serif;
}

.medium {
  font-family: "MontserratMedium", sans-serif;
}

.thin {
  font-family: "MontserratLight", sans-serif;
}

.image-wrapper {
  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.text-overflowed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
}

.break-spaces {
  white-space: break-spaces;
}

.nowrap {
  white-space: nowrap;
}
