@font-face {
  font-family: "MontserratBold";
  src: url("../assets/fonts/Montserrat-Bold.woff") format("woff");
}

@font-face {
  font-family: "MontserratMedium";
  src: url("../assets/fonts/Montserrat-Medium.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Regular.woff") format("woff");
}

@font-face {
  font-family: "MontserratLight";
  src: url("../assets/fonts/Montserrat-Light.woff") format("woff");
}
