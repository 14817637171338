@import "./variables";

.app-table {
  width: 100%;
  border-collapse: collapse;

  &__head {
    th {
      padding: 0 0 9px;
      font-size: 12px;
      color: transparentize($white, 0.5);
      font-weight: 100;
      vertical-align: top;
      .column-title__label {
        height: 55px;
      }
    }
  }

  &__content {
    font-size: 14px;
    color: $white;

    td {
      word-break: break-word;
      vertical-align: middle;
      padding: 15px 10px 15px 0;

      &:last-of-type {
        padding: 15px 0 15px;
      }
    }
  }

  &__row {
    border-bottom: 1px solid transparentize($white, 0.8);

    &:first-of-type {
      td {
        padding: 0 10px 15px 0;

        &:last-of-type {
          padding: 0 0 15px 0;
        }
      }
    }
  }

  .table-btn {
    min-width: 70px;
    height: 24px;
    padding: 3px 20px;
    font-size: 12px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    .custom-checkbox {
      margin-left: 10px;
    }
  }
}
