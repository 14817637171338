@import "src/styles/variables";

.notification {
  width: 250px;
  color: $white;
  background-color: $purple;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  word-break: break-all;
  padding: 15px;

  &__text {
    font-size: 14px;
    margin: 0 0 10px;
    text-align: left;
    white-space: pre-wrap;

    &:last-of-type {
      margin: 0;
    }
  }

  .cross {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
