@import "./variables";

.primary-btn {
  height: 42px;
  font-size: 14px;
  padding: 12px 30px;
}

.secondary-btn {
  height: 38px;
  font-size: 12px;
  padding: 12px 20px;
}

.btn-uppercased {
  text-transform: uppercase;
}

.btn-icon {
  &--right {
    .btn-icon__icon {
      margin-left: 6px;
    }
  }

  &--left {
    .btn-icon__icon {
      margin-right: 6px;
    }
  }
}

.single-icon-btn {
  background: none;
  border: none;
  color: $light-purple;
  cursor: pointer;
  padding: 0;

  // &:focus {
  //   box-shadow: 0px 0px 0px 1px $white;
  // }

  &:hover {
    transition: $transition;
    opacity: 0.8;
  }

  &:active,
  &.active {
    color: $shadow-blue;
    transition: $transition;
  }

  &:disabled {
    cursor: default;
    opacity: 0.2;

    &:hover,
    &:active,
    .active {
      color: $purple;
      transition: $transition;
    }
  }

  &--white {
    color: $white;

    &:active,
    &.active {
      color: $gray;
      transition: $transition;
    }
  }
}

.main-btn {
  border-radius: 4px;
  background: $purple;
  cursor: pointer;
  transition: $transition;
  color: $white;
  box-shadow: $btn-box-shadow;
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:focus {
    box-shadow: 0px 0px 0px 1px $white;
  }

  &:hover {
    transition: $transition;
    opacity: 0.8;
  }

  &:active,
  &.active {
    background: $shadow-blue;
    transition: $transition;
  }

  &:disabled {
    cursor: default;
    opacity: 0.2;

    &:hover,
    &:active,
    .active {
      background: $purple;
      transition: $transition;
    }
  }

  &--left {
    justify-content: flex-start;
  }
}

.outline-btn {
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  transition: $transition;
  color: $purple;
  box-shadow: $btn-box-shadow;
  border: 2px solid $purple;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:focus {
    box-shadow: 0px 0px 0px 1px $white;
  }

  &:hover {
    transition: $transition;
    opacity: 0.8;
  }

  &:active,
  &.active {
    color: $shadow-blue;
    border: 2px solid $shadow-blue;
    transition: $transition;
  }

  &:disabled {
    cursor: default;
    opacity: 0.2;

    &:hover,
    &:active,
    .active {
      color: $purple;
      border: 2px solid $purple;
      transition: $transition;
    }
  }

  &--left {
    justify-content: flex-start;
  }
}

button {
  outline: none;
}

.form-action-btn {

  &--spaces {
    letter-spacing: 0.1em;
  }
}
