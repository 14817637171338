@import "./variables";

@mixin chart-popup-frame {
  background: linear-gradient(transparentize($gradient-blue, 0) -10%, transparentize($gradient-blue, 1) 60%) !important;
  border: 2px solid $shadow-blue !important;
  color: white;
  border-radius: 4px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 15px;

  &:before {
    content: "";
    position: absolute;
    background: $black;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.chart-popup-wrap {
  background: transparent !important;
  border: 0 !important;
  width: 95%;
  display: flex !important;
  justify-content: center;
  //position: sticky !important;
  position: absolute;
  height: 100%;
  transform: translate3d(0, 0, 0) !important;
  top: 50px !important;
  order: 0;
  min-height: 45px;
  padding: 0 !important;
}

.chart-popup {
  height: 35px;
  position: sticky;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  //position: relative;
  column-gap: 15px;
  @include chart-popup-frame;

  &__element {

  }

  &__candle {
    &-value {
      &--red {
        color: $candlesRed;
      }

      &--green {
        color: $candlesGreen;
      }
    }
  }

  &__rsi {
    &-value {
      color: $rsi
    }
  }

  &__stoch {
    &-value {
      &--kLine {
        color: $stochK;
      }

      &--dLine {
        color: $stochD;
      }
    }
  }

  &__tal-value {
    &--short {
      color: $yellow;
    }

    &--long {
      color: $orange;
    }
  }
}

.chart-popup-rsi {
  position: absolute;
  left: 3px;
  top: 45.5%;
  @include chart-popup-frame;
}

.chart-popup-stoch {
  position: absolute;
  left: 3px;
  top: 70.5%;
  @include chart-popup-frame;
}
