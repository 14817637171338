.create-module-form {
  &__item {
    margin: 0 0 36px;
    max-width: 300px;
    width: 100%;
  }
  
  .outline-btn {
    .btn-icon__icon {
      transform: rotate(180deg);
    }
  }

  .form__btns {
    display: flex;
    justify-content: flex-start;
    button {
      margin: 0 20px 0 0;
    }
  }
}
