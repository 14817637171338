$black: #000;
$purple: #6d3c97;
$light-purple: #AA5DE6;
$white: #fff;
$blue: #299dd8;
$dark-blue: #10112a;
$red: #ed454a;
$green: #3fc665;
$gray: #343843;
$light-gray: #72747a;
$shadow-blue: #5a8093;
$gradient-purple: #262866;
$gradient-blue: #0D2840;

$candlesRed: #eb5454;
$candlesGreen: #47b262;
$rsi: #159415;
$stochK: #318EE8;
$stochD: #ED8011;
$yellow: #faff00;
$orange: #ff8a00;

$btn-box-shadow: inset 1px -1px 1px rgba(0, 0, 0, 0.25), inset -1px 1px 1px rgba(255, 255, 255, 0.25);
$transition: all 0.5s ease-out;

$main-header-height: 110px;
$left-bar-padding-top: 23px;
$left-bar-padding-right: 42px;
$left-bar-padding-bottom: 57px;
$left-bar-padding-left: 42px;
