@import './_variables.scss';

.MuiStepper-root.MuiStepper-root {
  background-color: transparent;
  span {
    color: $light-gray;
  }
  svg {
      &.MuiStepIcon-active {
          color: $light-purple;
      }
      color: $shadow-blue;
  }
}