@import "./variables";

.form {
  &__card {
    background: black;
    border: 2px solid $shadow-blue;
    padding: 40px 60px 60px;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 200%;
      z-index: 1;
      background: radial-gradient(transparentize($gradient-blue, 0.3) -0%, transparentize($gradient-blue, 1) 80%);
    }
  }

  &__card-content {
    z-index: 2;
    position: relative;
  }

  .title2 {
    margin: 0 0 10px;
  }

  &__btns {
    margin: 50px 0 0;
    z-index: 2;
    position: relative;
  }

  &__item {
    margin: 0 0 40px;
    &:last-of-type {
      margin: 0;
    }
  }
}

.dialog-form-header {
  margin: 0 0 30px;

  .title2 {
    margin: 0 0 5px !important;
  }

  &__description {
    color: $light-purple;
    font-size: 16px;
    line-height: 20px;
    font-family: "Montserrat", sans-serif;
  }
}
