@import "./variables";

.thumb-horizontal {
  background: $shadow-blue;
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  height: 10px;
}

.thumb-vertical {
  position: relative;
  display: block;
  height: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: $shadow-blue;
  width: 10px;
}
