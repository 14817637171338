@import './variables';
@import "./fonts";
@import "./common.scss";
@import "./buttons.scss";
@import "./create-module.scss";
@import "./form.scss";
@import "./table.scss";
@import "./scrollbar.scss";
@import "./stepper.scss";
@import "./chart-popup.scss";

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
  min-height: 100%;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  background: $black;
  color: $white;
  font-size: 12px;
}

// Scrollbar
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}
